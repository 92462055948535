import React, {Component} from "react";
import ReactDOM from "react-dom";

import Home from "../../../components/personal/home/Home";
import About from "../../../components/personal/home/About";
import Contact from "../../../components/personal/home/Contact";
import Work from "../../../components/personal/home/Work";
import Publications from '../../../components/personal/Publications';


class HomeLayout extends Component {
  render() {
    const params = new URLSearchParams(this.props.location?.search);
    const goTo = params.get('goTo'); // bar
    const shouldScrollToContact = goTo === 'contact';
    const shouldScrollToAbout = goTo === 'about';
    const shouldScrollToHome = goTo === 'home';
    const shouldScrollToWork = goTo === 'work';
    const shouldScrollToPublications = goTo === 'publications';
    return (
      <div>
        <Home scrollTo={shouldScrollToHome}/>
        <About scrollTo={shouldScrollToAbout}/>
        <Work scrollTo={shouldScrollToWork}/>
        <Publications scrollTo={shouldScrollToPublications}/>
        <Contact scrollTo={shouldScrollToContact}/>
      </div>)
  }
}
export default HomeLayout;
