import React, {Component} from "react";
import ReactDOM from "react-dom";
const queryString = require('query-string');

import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';

class Home extends Component {

  render() {

    return (<Element name="home-section">
      <div className="home-personal-bg">
        <br/>
        <div className="container">
          <div className="row  home-content">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="home-personal-title">Jeffrey Okawa - Full Stack Developer</div>
              <div className="home-personal-desc">My name is Jeff Okawa and I am a Toronto based Software developer with 15 years experience in insurance, financial services, accounting, health care and technology sectors.</div>
            </div>
            <div className="col-2"></div>
          </div>
        </div>

      </div>
    </Element>)
  }
}
export default Home;
