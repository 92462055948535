import React, {Component} from "react";
import ReactDOM from "react-dom";

class CaseStudy extends Component {
  render() {
    return (<div >
      <br/><br/>
      <div className="container">
        <div className="row">
          <div className="col"></div>
          <div className="col">
            <div className="clients-title">Case Studies</div>
          </div>
          <div className="col"></div>
        </div>

        <div className="row-space"></div>
        <div className="row-space"></div>

        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            <img className="mx-auto d-block" src="assets/images/clients/case_study_aws.png"/>

            <br/>
            <div className="about-title case-study-title">Save $200K/year with Open Source</div>
            <div className="about-title case-study-desc">
              Learn now our principle consultant helped a large organization save over $200k/year of infrastructure costs by planing and implementing a transition from Oracle and Weblogic Servers to Postgres and Tomcat.
            </div>
          </div>
          <div className="col-2"></div>
        </div>

        <div className="row-space"></div>
        <div className="row-space"></div>

        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            <img className="mx-auto d-block" src="assets/images/clients/case_study_stripe.png"/>

            <div className="about-title case-study-title">Application Monitization Though Stripe</div>
            <div className="about-title case-study-desc">
              Not a fan of the end-of-flow traditional shopping cart experience, the client wanted users to be able to purhse produces/services as they navigated thoughout the application.
              Our princople consultant lead a team to design, implementation and PCI rquirments of a highly interactive monitization system into the client application.
            </div>
          </div>
          <div className="col-2"></div>
        </div>

        <div className="row-space"></div>
        <div className="row-space"></div>


        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            <img className="mx-auto d-block" src="assets/images/clients/case_study_yt.png"/>

            <div className="about-title case-study-title">Youtube as a Video Hosting Platform: Then and Now </div>
            <div className="about-title case-study-desc">
              The Media League is a competitive creativity league where high school students upload meida of various types. Youtube was chosen as the hosting platform but could it be used as a hosting platform today?  No.  Find out why.
            </div>
          </div>
          <div className="col-2"></div>
        </div>


        <div className="row-space"></div>
        <div className="row-space"></div>
      </div>
    </div>)
  }
}
export default CaseStudy;
