import React, {Component} from "react";
import ReactDOM from "react-dom";
import { getAppName } from '../../services/app-service'

class Projects extends Component {


  render() {
    const appName = getAppName();


    return (<div>
            <div className="body-content">

        <div className="container">
          <div className="row">
            <div className="col"></div>
            <div className="col">
              <div className="clients-title">Company Experience</div>
            </div>
            <div className="col"></div>
          </div>

          <div className="row">
            <div className="col-4">
              <img className="clients-exp-desc-img" src="assets/images/clients/java.png" alt=""/>
              <div className="clients-exp-desc">
                Our constants has over 10 years of Java, J2EE, and web development experience successfully delivering applications in complex environments.
              </div>
            </div>
            <div className="col-4">
              <img className="clients-exp-desc-img" src="assets/images/clients/full_cycle.png" alt=""/>
              <div className="clients-exp-desc">
                Full lifecycle experience from requirements gathering though to development and testing. Experience with application security reviews, code review audits, and production deployments.
              </div>
            </div>
            <div className="col-4">
              <img className="clients-exp-desc-img" src="assets/images/clients/grails.png" alt=""/>
              <div className="clients-exp-desc">
                Experience with the Grails framework for applications in social media and medical insurance industries.
              </div>
            </div>
          </div>

          <div className="row-space"></div>

          <div className="row">
            <div className="col-4">
              <img className="clients-exp-desc-img" src="assets/images/clients/server_change.png" alt=""/>
              <div className="clients-exp-desc">
                Participated in a project where we architected and planned a multi-year transition from Weblogic/Jboss servers using Oracle databases to AWS Tomcat servers with RDS Postgres databases.
                The move using Open Source produces increase performance and reliability.  Six digit cost savings (over 3 years) were acheived.
                <br/>
              </div>
            </div>
            <div className="col-4">
              <img className="clients-exp-desc-img" src="assets/images/clients/money.png" alt=""/>
              <div className="clients-exp-desc">
                Experience building applications in the medical insurance, financial services, and ecommerice industries.  Experience building
                applications with HIPPA and PCI requirnments. We area able to work with and lead the team though security audits and reviews.
                <br/>
              </div>
            </div>
            <div className="col-4">
              <img className="clients-exp-desc-img" src="assets/images/clients/react.png" alt=""/>
              <div className="clients-exp-desc">
                Built applications using modern UI frameworks.  Our consultants can easily integrate into your team and work with your UX designs
                to build mobile-first with rich user interfaces.
                <br/>
              </div>
            </div>
          </div>

          <div className="row-space"></div>

          <div className="row">
            <div className="col-4">
              <img className="clients-exp-desc-img clients-exp-desc-img-book" src="assets/images/clients/book.png" alt=""/>
              <div className="clients-exp-desc">
                An introduction into federated identities, social logins, SMAL, and OpenID Conncet.  The purpose of the book is to teach a basic understanding of
                the fundamentals of single-sign-on.  Coveres OAuth 1.0a and 2.0 as a preluede to OpenID Connect.
              </div>
            </div>
            <div className="col-4">
              <img className="clients-exp-desc-img" src="assets/images/clients/angular.png" alt=""/>
              <div className="clients-exp-desc">
                Rebuilt a large organizations front-end application using Angular 1.x.  The client inherited an custom non-restful Java based JSP front-end, however,
                to comply with business requirment of modern user experience, the UI was re-written from the ground up in Angular 1.5.    <br/><br/>This multi-year task included
                developing the UI build process, structuring the project, implemeting, and production deployment/support.
              </div>
            </div>
            <div className="col-4">
              <img className="clients-exp-desc-img" src="assets/images/about/buzzybugbug.png" alt=""/>
              <div className="clients-exp-desc">
                Though the BuzzyBugBug brand, {appName}, creating, publishing, and marking various videos for the YouTube platform.
                <br/><br/>The work includes video editing, flash animations, and social media marketing.  Youtube and internet based distribution of
                entertainment is the future.  BuzzyBugbug brand allows {appName} to keep in touch with this trend to provide clients with first hand experience with
                their social media needs.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row-space"></div>
      <div className="row-space"></div>
    </div>)
  }
}
export default Projects;
