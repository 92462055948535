import React, {Component} from "react";
import ReactDOM from "react-dom";
import {Link, Route, Switch} from 'react-router-dom';
import {DirectLink, Events, animateScroll as scroll, scrollSpy, scroller} from 'react-scroll';
import NavItem from '../../components/navigation/NavItem';
import {getNavigationData} from '../../services/app-service';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navAtTopClassName: "navbar navbar-expand-lg navbar-light bg-light  fixed-top",
      navAtTop: true
    }

  };

  componentDidMount() {
    //window.addEventListener('scroll', this.handleScroll.bind(this));
  };

  handleScroll(event) {
    var height = $(window).scrollTop();

    if (height == 0) {
      this.setState({navAtTopClassName: "navbar navbar-expand-lg navbar-light bg-light  fixed-top nav-hide", navAtTop: false});
    } else {
      if (!this.state.navAtTop) {
        this.setState({navAtTopClassName: "navbar navbar-expand-lg navbar-light bg-light fixed-top ", navAtTop: true});
      }

    }
    event.preventDefault();
  };

  render() {

    var navItemsFromConfig = getNavigationData();
    var navItemsToDisplay = [];
    for(var i=0; i<navItemsFromConfig.headings.length;i++){
      var navItem = navItemsFromConfig.headings[i];
      navItemsToDisplay.push(
        <li key={i} className="nav-item">
          <NavItem  navUri={navItem.uri} navName={navItem.sectionName} title={navItem.title}/>
        </li>
      );
    }

    const titleForLogo = <img src="assets/images/layout/logo.png" width="30" height="30" alt=""/>;
    const currentLocation = location.pathname;

    return (<nav className={this.state.navAtTopClassName}>
      <div className="container">

        <button className="navbar-toggler nav-hamburger" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {navItemsToDisplay}
          </ul>
        </div>
      </div>
    </nav>)
  }
}

export default Header;
