import React, {Component} from "react";
import ReactDOM from "react-dom";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';

class Work extends Component {

  constructor(props) {
    super(props);

  }

  componentDidMount() {

    const scrollTo = this.props.scrollTo;

    if (scrollTo) {
      scroller.scrollTo('work-section', {
        duration: 800,
         offset: -50,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }
  };

  render() {
    return (<div className="">
      <Element name="work-section" >

        <div className="row-space"></div>

        <div className="row">
          <div className="col"></div>
          <div className="col">
            <div className="about-us-products about-title">
              Experience and Tech Stack
            </div>
          </div>
          <div className="col"></div>
        </div>

        <div className="row-space"></div>
        <div className="row about-images">
          <div className="col-3"></div>

          <div className="col-2">
            <img src="assets/images/work/coding.png" alt=""/>
            <div className="home-personal-about-title">Languages</div>
            <div className="home-personal-about-desc">
              Java/J2EE, Grovy, JavaScript, Ruby(Rails), Python, HTML/CSS, XML, XSL, CSS, SQL, Matlab, Regex.
            </div>

          </div>
          <div className="col-2">
            <img src="assets/images/about/computer.png" alt=""/>
            <div className="home-personal-about-title">Frameworks</div>
            <div className="home-personal-about-desc">
              Spring, SpringBoot, Spring MVC, Spring Security, JNDI, Hibernate and JPA, Webservices, WSDL, SOAP, REST, OAuth, JAXB, JDBC, EJB, Grails, Node Web Kit, Electron, Rails, Struts, JSP/Servlets, Junit
            </div>
          </div>
          <div className="col-2">
            <img src="assets/images/about/web.png" alt=""/>
            <div className="home-personal-about-title">Front-End Development
            </div>
            <div className="home-personal-about-desc">
              React, JavaScript, AngularJS 1.x, jquery, NodeJS, CSS, and other frameworks
            </div>
          </div>
          <div className="col-3"></div>
        </div>

        <div className="row-space"></div>

        <div className="row  about-images">
          <div className="col-3"></div>
          <div className="col-2">
            <img src="assets/images/about/wrench.png" alt=""/>
            <div className="home-personal-about-title">Build Tools</div>
            <div className="home-personal-about-desc">
              Experience with Team City, Jenkins, Maven, Ant, GIT, SVN, CVS, Grunt, Webpack, Node, Bower
            </div>
          </div>
          <div className="col-2">
            <img src="assets/images/work/strategy.png" alt=""/>
            <div className="home-personal-about-title">Tech Leadership</div>
            <div className="home-personal-about-desc">
              5+ years of team leadership experience managing team sizes ranging from 3 to 12 junior, intermediate, and senior developers.
            </div>
          </div>
          <div className="col-2">
            <img src="assets/images/about/design.png" alt=""/>
            <div className="home-personal-about-title">Design</div>
            <div className="home-personal-about-desc">
              Full lifecycle experience from requirements gathering though to development and testing. Experience with application security reviews, code review audits, and production deployments.

            </div>
            <div className="col-3"></div>
          </div>
        </div>
        <div className="row-space"></div>
        <div className="row-space"></div>
      </Element>
    </div>)
  }
}
export default Work;
