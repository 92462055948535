import React, {Component} from "react";
import ReactDOM from "react-dom";
import CaseStudy from "./CaseStudy";
import Projects from "./Projects";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll'

class Clients extends Component {
  componentDidMount() {

    scroller.scrollTo('clients-section', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  };


  render() {
    return (
      <div>
      <Element name="clients-section">
      <div className="body-content clients-bg">
        <br/>
        <div className="container">

          <div className="row">
            <div className="col-4"></div>
            <div className="col-2">
              <img src="assets/images/clients/gears.png" alt="" className="float-right"/>
            </div>
            <div className="col-3">
              <div className="client-project-desc">
                Take a look at what our principle consultants have worked on.
              </div>
            </div>
            <div className="col-3"></div>
          </div>

          <div className="row-space"></div>

          <div className="row">
            <div className="col-4"></div>
            <div className="col-2">
              <img src="assets/images/clients/search.png" alt="" className="float-right"/>
            </div>
            <div className="col-3">
              <div className="client-project-desc">
                Or view real worked case studies on of our work.
              </div>
            </div>
            <div className="col-3"></div>
          </div>
        </div>
      </div>
      </Element>
      <Projects/>
      <CaseStudy/>
    </div>
    )
  }
}
export default Clients;
