import React, {Component} from "react";
import ReactDOM from "react-dom";
import {Link, Route, Switch} from 'react-router-dom';

import Footer from "./Footer";
import Header from "./navigation/Header";
import Main from "./Main";

class Layout extends Component {

  render() {

    return (<div>

      <Header/>
      <Main/>
      <Footer/>

    </div>)
  }
}
export default Layout;
