import logo from './logo.svg';
import {BrowserRouter} from 'react-router-dom'
import Layout from "./components/Layout";
import './App.css';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
    </div>
  );
}

export default App;
