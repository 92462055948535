import React, { Component } from "react";
import ReactDOM from "react-dom";
import {Link, Route, Switch} from 'react-router-dom';
import {DirectLink, Events, animateScroll as scroll, scrollSpy, scroller} from 'react-scroll';

class NavItem extends Component{

  constructor(props) {
    super(props);


    this.handleClick = this.handleClick.bind(this);
  };

  handleClick(event) {
    event.preventDefault();
    var offset = 0;
    if (this.props.navName == 'work'){
      offset=-50;

    }
    scroller.scrollTo(this.props.navName+"-section", {
      duration: 800,
      delay: 0,
      offset:offset,
      smooth: 'easeInOutQuart'
    })
  };

  render(){
    const currentLocation = location.pathname;
    if (currentLocation == this.props.navUri) {

      return (<a className="nav-link" href="#" onClick={this.handleClick}>{this.props.title}</a>);
    };

    const goToParam = this.props.navUri+"?goTo="+this.props.navName;

    return (
      <Link className="nav-link" to={goToParam}>
        {this.props.title}
      </Link>
    );
  }
}
export default NavItem;
