import React, {Component} from "react";
import ReactDOM from "react-dom";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';

class Contact extends Component {

  constructor(props) {
    super(props);


  }


  componentDidMount() {

    const scrollTo = this.props.scrollTo;

    if (scrollTo) {
      scroller.scrollTo('contact-section', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }
  };

  render() {
    return (<div className="contact-personal-bg">
      <Element name="contact-section">

        <br/>
        <div className="container">

          <div className="row">
            <div className="col"></div>
            <div className="col">
              <div className="about-us-title about-title">Contact Me!</div>
            </div>
            <div className="col"></div>
          </div>
          <div className="row">
            <div className="col"></div>
            <div className="col">
              <div className="about-title">Need help on a project or just want to chat? </div>
            </div>
            <div className="col"></div>
          </div>


          <div className="row-space"></div>

          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <a href="mailto:jeff.okawa@gmail.com?Subject=Hello">
                <img className="cs_img" src="/assets/images/home/email.png" alt=""/>
              </a>
            </div>
            <div className="col-4"></div>
          </div>

          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <a href="https://ca.linkedin.com/in/jeffrey-okawa" target="_blank">
                <img className="cs_img" src="/assets/images/home/linkedin.png" alt=""/>
              </a>
            </div>
            <div className="col-4"></div>
          </div>

        </div>

      </Element>
    </div>)
  }
}
export default Contact;
