import React, {Component} from "react";
import ReactDOM from "react-dom";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';

class CSAws extends Component {
  render() {

    return (<Element name="cs-aws-section">
      <div className="body-content">
        <br/>
        <div className="container">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="cs_h1">From Oracle to Open Source</div>

              <img className="cs_img" src="/assets/images/cs/aws/past.png" alt=""/>
              <div className="cs_h1">The Problem</div>
              <p>
                The client inherited an Java servlet-based application running on a “Oracle” stack. It was tied to using Weblogic application servers, an Oracle database, and JDeveloper to build out middleware components (and create deployable artifacts).
              </p>
              <p>
                In addition to the “obsolete” stack (well, except the database), code base was a giant ball of spaghetti and suffered numerous performance issues.  This lead the client to run multiple backup servers as failovers when one would crash.  Not to mention the poor performance impacted end users.
              </p>
              <p>
                From a cost standpoint, there were many business concerns with the current system:
              </p>
              <ul>
                <li>
                  The Weblogic licences cost over 100k/year. Incurring additional licence costs for backup servers.
                </li>
                <li>
                  The DB licence itself was in the 100K/year range.
                </li>
                <li>
                  The build process using JDeveloper was manual and consumed significant developer time.
                </li>
              </ul>
              <div className="cs_h1">Why Not Start Over?</div>
              <img className="cs_img" src="/assets/images/cs/aws/refresh.png" alt=""/>
              <p>
                At this point, some may ask why we just didn’t toss it out and start over. Time to market constraints and developer capacity resulted having no choice but to use the current stack and release “version 1” of the product to market.
              </p>
              <p>
                It should be noted that Oracle technologies are proven enterprise products. Poor code was the cause of the performance issues.
              </p>

              <div className="cs_h1">The Challenge</div>
              <img className="cs_img" src="/assets/images/cs/aws/route.png" alt=""/>
              <p>
                Regardless of the poor code, business determined that the licence fees for running an Oracle stack was not justified and tasked development/infrastructure teams were with 4 broad goals:
              </p>
              <ol>
                <li>
                  Get some form of automated testing and continuous integration.
                </li>
                <li>
                  Fix the application performance problems.
                </li>
                <li>
                  Reduce infrastructure costs.
                </li>
                <li>
                  Reduce development contention by introducing modern tools and frameworks.
                </li>
              </ol>

              <div className="cs_h1">The Proposal</div>
              <img className="cs_img" src="/assets/images/cs/aws/migration_path.png" alt=""/>

              <p>
                The first step involved outlining the end goals of the desired infrastructure (in conjunction with application changes also taking place). The proposition was put forward to move off the Oracle stack completely to a purely open source platform. Though AWS will still be used, the end goal was to avoid costly licence costs and run smaller sized servers.
              </p>
              <p>
                This is easier said than done! The development team only had a small working window to address technical-debt each year, afterwards dev capacity would be committed to other projects. Working with business, we determined a 3-year plan to gradually migrate the application to the new stack.
              </p>
              <p>
                I had to work with the architects and IT developers to recommend a open source stack (in an environment where corporate policies favour enterprise technologies) and propose a digestible plan that would give business confidence that the new stack would be maintainable and perform under load.
              </p>
              <p>
                This ”phased” process was necessary for several reasons.
              </p>

              <ol>
                <li>
                  Team capacity was the primary factor. With limited time each year to fully complete each step, breaking the migration into logical portions was necessary.
                </li>
                <li>
                  Corporate concerns with moving off Oracle DB due to existing in-house Oralce expertise. Moving off the Oracle DB would be done in the final phase only after the rest of the application is stabilized.
                </li>
                <li>
                  Like the Oracle DB concerns, business wanted adequate paid support (for emergencies) on any application server. Because of this, an JBoss was chosen as an intermediary server during the application refactoring stage. Tomcat has no vender provided paid support.
                </li>
              </ol>

              <div className="cs_h1">The Plan</div>
              <img className="cs_img" src="/assets/images/cs/aws/plan.png" alt=""/>
              <div className="cs_h2">Year 1</div>
              <img className="cs_img" src="/assets/images/cs/aws/cleaning.png" alt=""/>
              <p>
                The first step was to get some form of automated testing and scripting of the build process. Building JDeveloper specific scripts wasn’t practical, so it was decided to incorporate Maven and Jenkins (continuous integration) into our build process.
              </p>

              <p>
                The application underwent a significant architectural change:
              </p>
              <ol>
                <li>
                  A new UI was built for the application and the old JSP pages (served out by the application server) were removed.
                </li>
                <li>
                  This UI was built using Angular JS (1.x) and served by an Apache webserver.
                </li>
                <li>
                  The Java backend application would become “lightweight” and act as a resource server providing a API services though REST.
                </li>
              </ol>
              <p>
                The custom web servlet framework was replaced with Springboot and the middleware components were replaced with Hibernate entities (though the database schema remained unchanged).
              </p>
              <p>
                No longer using the Oracle middleware components made it significantly easier to break away from Weblogic. Weblogic licences were a significant pain point for business and changing over to another server was a major win.
              </p>
              <p>
                My responsibilities for this phase included:
              </p>
              <ol>
                <li>
                  Designing out the new Springboot application.
                </li>
                <li>
                  Proposing the split of JSPs and incorporation of a UI tech stack.
                </li>
                <li>
                  Participation in the implementation of the Angular UI and java backend.
                </li>
              </ol>

              <div className="cs_h2">Year 2</div>
              <p>
                The database schema was a major area of concern and would be the primary focus of year 2’s efforts.   The schema was re-designed from the ground up, but we continued to use the Oracle database.
              </p>
              <p>
                By the end of year 2, most Java components were refactored.  The database schema (and Hibernate entities) were in good shape.  This was confirmed by looking at DB utilization and other performance metrics in the production environment. At this point, we were ready for phase 3.
              </p>
              <p>
                I was required to oversee the design of the data schema and work with the business, data analytics, and finance teams to ensure adequate data capture.
              </p>

              <div className="cs_h2">Year 3</div>
              <img className="cs_img" src="/assets/images/cs/aws/aws.png" alt=""/>
              <p>
                Phase 3 involved more infrastructure changes to meet our end goals.  This involved:
              </p>
              <ol>
                <li>
                  In the previous years, our application ran on 4 large application servers.  To reduce costs, 2 smaller instances were used with the ability to add servers on-demand using auto-scaling.
                </li>
                <li>
                  There were no longer any technical reasons for using an Oracle DB.  Analysis of previous year performance resolved any residual concerns about moving to Postgres.  The use of Hibernate and decoupling the business services with the database made the migration process to Postgres possible.
                </li>
                <li>
                  With a lightweight Java application, a full J2EE container was no longer required.  The move from JBoss to Tomcat also allowed our auto-scaling infrastructure to spin up servers more quickly.
                </li>
                <li>
                  Our application was not stateless and required the servers to maintain state during user sessions. However, the use of sticky-sessions isn’t ideal for auto-scaling and deployment stargates.  Therefore, Redis was used as a session store.
                </li>
              </ol>

              <p>
                My responsibilities included:
              </p>
              <ol>
                <li>
                  Working closely with the IT team to create the new infrastructure in the AWS environment
                </li>
                <li>
                  Overseeing the load and performance testing to ensure the application performed adequately in the new stack.
                </li>
              </ol>
              <div className="cs_h1">Conclusion</div>
                            <img className="cs_img" src="/assets/images/cs/aws/conclusion.png" alt=""/>
              <p>
                With any large organization, there is always strong favouritism towards enterprise products with paid support -and there is good reason for this.   That being said, it’s all to common for decision makers to favour these products simply because those are the only ones they’ve work with.  In many situations, open source products are not only cheaper but more than adequate to perform the required task.
              </p>
              <p>
                Stakeholders need to understand both the risks and your proposals to mitigate them.   The hope with this case study is to show that moving to an open source stack may require a plan with several phases to convince stakeholders that open source products can do the job.
              </p>

            </div>
            <div className="col-2"></div>
          </div>
          <div className="row-space"></div>
                <div className="row-space"></div>
                <div className="row-space"></div>
                      <div className="row-space"></div>
        </div>

      </div>
    </Element>)
  }
}
export default CSAws;
