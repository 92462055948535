import React, { Component } from "react";
import {  Route, Routes } from 'react-router-dom';
import HomeLayout from "./personal/home/HomeLayout";

import Publications from "./personal/Publications";
import Clients from "./clients/Clients";

import CSAws from "./clients/CSAws";
import CSmonetization from "./clients/CSmonetization";


class Main extends Component {
  
  render() {


    return (<div >
        <Routes>
          <Route path="/" exact={true} element={<HomeLayout />} />
          <Route path="/cs/aws" element={CSAws} />
          <Route path="/cs/monetization" element={<CSmonetization/>} />
        </Routes>
    </div>)
  }
}
export default Main;
