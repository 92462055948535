import React, { Component } from "react";
import ReactDOM from "react-dom";
import {getCopyrightNotice} from '../services/app-service';

class Footer extends Component{
  render(){
    return (

      <div className="footer-bg">
        <div className="container">

          <div className="row ">
            <div className="col-3"></div>
            <div className="col-6">
              <div className="about-title footer-top-space">{getCopyrightNotice()}</div>
            </div>
            <div className="col-3"></div>
          </div>
        </div>


      </div>
    )
  }
}
export default Footer;
