import React, {Component} from "react";
import ReactDOM from "react-dom";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';

class Publications extends Component {

  componentDidMount() {

  }
  render() {
    return (<div className="body-content publication-section">
      <Element name="publications-section">

        <div className="row-space"></div>

        <div className="row">
          <div className="col"></div>
          <div className="col">
            <div className="publication-section-title about-title">
              Publications
            </div>
          </div>
          <div className="col"></div>
        </div>

        <div className="row-space"></div>

        <div className="row">
          <div className="col-2">
          </div>
          <div className="col-2">
            <img className="publications-images" src="assets/images/publications/uhn.png" alt=""/>
            <a href="assets/files/SPIE2007.pdf" target="_blank" className="publication-download-link">Download Publication</a>

          </div>
          <div className="col-6">

            <div className="publications-title">Conformal light delivery using tailored cylindrical diffuser</div>
            Optical Methods for Tumor Treatment and Detection: Mechanisms and Techniques in Photodynamic Therapy XVI, 2007.
            <div className="publications-desc">
              By &nbsp;
              <span className="publication-author">A. Rendon, J. Okawa, R. Weersink, J.C. Beck and Lothar Lilge
              </span>
              <div>
                <br/>
              Photodynamic Therapy (PDT) is a method of cancer treatment that allows medical professionals to selectively destroy unwanted cancer tissues while leaving normal tissue undamaged. The research I was involved with was to model the photodynamic theapry problem into a computational problem and to research and create stochastic local search algorithms (algorithms that base their search decisions on probability) to determine the best placement of optical fibers in the tumor and the correct power level distributions along these fibers.

              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
        <div className="row-space"></div>
        <div className="row">
          <div className="col-2">
          </div>
          <div className="col-8">
            <hr className="publication-hr" />
          </div>
          <div className="col-2">
          </div>
        </div>

        <div className="row-space"></div>

        <div className="row">
          <div className="col-2">
          </div>
          <div className="col-2">
            <img className="publications-images" src="assets/images/publications/cheating.png" alt=""/>
          </div>
          <div className="col-6">

            <div className="publications-title">The Effects of Cheating in First Person Shooters: Discussion and Research Topics of Interest, 2007</div>
            <div className="publications-desc">
              By &nbsp;
              <span className="publication-author">J. Okawa</span>
              <div>
                Cheating is becoming an increasing concern among all stakeholders (creators, server administrators, and players) of online gaming. In this paper, we take a look at how punishing cheaters effects the overall gameplay on a server.
              </div>
            </div>
            <div className="col-2"></div>

          </div>
        </div>
        <div className="row-space"></div>
        <div className="row">
          <div className="col-2">
          </div>
          <div className="col-8">
            <hr className="publication-hr" />
          </div>
          <div className="col-2">
          </div>
        </div>

        <div className="row-space"></div>

        <div className="row">
          <div className="col-2"></div>
              <div className="col-2">
                <img className="publications-images" src="assets/images/publications/languify.png" alt=""/>
              </div>
          <div className="col-6">

            <div className="publications-title">Development Of Languify: Interfacing with machine Translation, 2006.</div>
            <div className="publications-desc">
              By  &nbsp;
              <span className="publication-author">J. Okawa</span>
              <div>
                Languify is a open-source web-based translation management system which provides users with their own sub-domain for hosting translation projects, a method for importing and exporting translations, a user management system, and a versioning system for the translation projects. During the term, I worked on developing an interface between Languify and several machine translation systems to provide Languify users with machine translation capabilities with their projects.
              </div>
            </div>
            <div className="col-2"></div>
          </div>
        </div>

        <div className="row-space"></div>
      </Element>
    </div>)
  }
}
export default Publications;
