import React, {Component} from "react";
import ReactDOM from "react-dom";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';

class CSmonetization extends Component {
  render() {
    return (<Element name="cs-monetization-section">
      <div className="body-content">
        <br/>
        <div className="container">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="cs_h1">Monetization</div>

              <img className="cs_img" src="/assets/images/cs/monetization/monetization1.png" alt=""/>
              <div className="cs_h1">The Problem</div>
              <p className="cs-disclaimer">
                Disclaimer: This article is not sponsored by Stripe.
              </p>
              <p className="cs-disclaimer">
                Note:  This article was written based on the payment processor landscape of 2016 which has evolved significantly since then.
                           </p>
              <p>
                As the user navigated the application, the client’s prior monetization strategy involved showing a list of purchasable items at the end of the user’s expected flow. They would then be taken though the standard shopping cart experience.  Conversion rates were low due to the purchase pages being at the end of the flow and taking users off-site to an external third-party shopping cart was less than ideal.                        </p>
              <img className="cs_img" src="/assets/images/cs/monetization/monetization2.png" alt=""/>
              <p>
                Business decided to remove the existing shopping cart flow and instead allow users to purchase products one-at-a-time, on-demand, in whatever page they happen to be on. These new pages (or “callouts”) would appear at various times as the user navigated though the application (as opposed to the end).
              </p>
              <img className="cs_img" src="/assets/images/cs/monetization/monetization3.png" alt=""/>
              <p>
                In addition to the UI design, business and corporate policies imposed the additional requirements:
              </p>
              <ul>
                <li>
                  The application must stay at the current PCI SAQ A compliance level.
                </li>
                <li>
                  The chosen vender must be PCI compliant and have supporting documentation.
                </li>
                <li>
                  Purchasing system must support both English (CA) and French (CA).
                </li>
                <li>
                  Support coupons and discounts.
                </li>
                <li>
                  Must have the ability to create test accounts.
                </li>
                <li>
                  Users must be able to purchase without creating an account with the payment system.
                </li>
              </ul>

              <div className="cs_h1">PCI Compliance</div>
              <img className="cs_img" src="/assets/images/cs/monetization/pci.png" alt=""/>
              <p>
                Maintaining a SAQ A level of PCI compliance meant we had to use a iframe embedded widget provided by the payment processor.  Of all the payment processors, only a very limited set provided a rich enough UI widget that could be customizable and supported internationalization. </p>
              <p>
                As our application was a single-page-app, payment processors which required redirects to external payment pages were immediately excluded. We needed the widget to be some type of overlay over the existing content.
              </p>
              <p>
                To maintain our PCI compliance, it is not enough to assume a payment processor handles sensitive data “properly”.   It is required that we do the due diligence and collect the relevant documentation which states the chosen vender is indeed PCI compliant.  Some payment processors disclose this information upfront and provide download links to the aforementioned documentation.  To my surprise, there existed payment processors that could not disclose such information (even when asked) making their PCI complaint status questionable at best.
              </p>
              <div className="cs_h1">UX Design Compromise
              </div>
              <img className="cs_img" src="/assets/images/cs/monetization/ux-design.png" alt=""/>
              <p>
                Initially, UX designers had all sorts of designs for payment widgets. However, to maintain SAQ A, we had to use pre-built widgets provided by the processor. No exceptions. This meant setting clear expectations to UX on what is and is not customizable for a processor’s widget.
              </p>
              <div className="row">
                <div className="col-1">
                  <img className="cs_img" src="/assets/images/cs/monetization/warning.png" alt=""/>
                </div>
                <div className="col-11">
                  This has changed over the last year. Many payment processors provide the ability to create highly customized forms, yet still have it rendered within a &lt;iframe&gt; for minimal PCI compliance requirements. Whether they still fall in SAQ A or A-EP is questionable.
                </div>
              </div>
              <br/>
              <p>
Stripe was chosen as the new payment processing system. At the time, it provided the richest widget (Stripe checkout) that supported internationalization.  Because no pre-built widget will satisfy all UX wants, it’s important to set a clear goals and communicate constraints to ensure all stakeholders are happy with the outcome.              </p>
            </div>
            <div className="col-2"></div>
          </div>
        </div>

      </div>
    </Element>)
  }
}
export default CSmonetization;
