import React, {Component} from "react";
const data = {
  "personal": {
    "headings": [
      {
        "title": "Home",
        "uri": "/",
        "sectionName": "home"
      },
      {
        "title": "About",
        "uri": "/",
        "sectionName": "about"
      },
      {
        "title": "Work",
        "uri": "/",
        "sectionName": "work"
      },
      {
        "title": "Publications",
        "uri": "/",
        "sectionName": "publications"
      },
      {
        "title": "Contact",
        "uri": "/",
        "sectionName": "contact"
      }
  ]
  }
};

export function getAppName() {
  return "Dev78 Digital"
}

const isPersonal = true;
export function isPersonalSite() {
  return isPersonal;
}

export function isBusinessSite() {
  return !isPersonal;
}

export function getNavigationData(){
  if (isBusinessSite()){
    return data.business;
  }
  return data.personal;
}

export function getCopyrightNotice(){
    return "© 2023 Jeffrey Okawa.  All rights reserved."
}

export function getBlogEntriesFileName(){
  if (isBusinessSite()){
    return "business-blog-entries.json";
  }
  return "personal-blog-entries.json";
}
