import React, {Component} from "react";
import ReactDOM from "react-dom";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll'

class About extends Component {

    componentDidMount() {

      const scrollTo = this.props.scrollTo;

      if (scrollTo) {
        scroller.scrollTo('about-section', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart'
        })
      }
    };

  render() {

    return (<Element name="about-section">
      <div className="">
        <br/>

        <div className="container">
          <div className="row">
            <div className="col"></div>
            <div className="col">
              <div className="about-us-title about-title">About</div>
            </div>
            <div className="col"></div>
          </div>

          <div className="row">
            <div className="col-3">
              <img className="home-personal-about-image" src="assets/images/about/jeffreyokawa.png" alt=""/>
              <div>
                <div className="home-personal-education-title">University of Toronto</div>
                <div className="home-personal-education-desc">Honors Bachelor of Science, Specialist in Software Engineering
                </div>
                <br/>
                <div className="home-personal-education-desc">Resume:</div>
                <a href="assets/files/Jeff_Okawa_resume.pdf" target="_blank"><img className="home-personal-about-resume-image" src="assets/images/about/pdf_icon.png"/></a>
              </div>
            </div>
            <div className="col-9">
              I have over 15 years of software development experience in various fields including Java applications in high performance enterprise environments, 
              deployment and maintenance of application in cloud environments, and various front-end web technologies. 
              <br/>
              <br/>
              I have experience in a variety of business environments and extensive experience working with business and other stackholders to learn the domain, 
              understand the problem, and provide solutions.
              <br/>
              <br/>
              Areas of Expertise:
              <br/>
              <ul>
                <li>
                  Technical team leadership
                </li>
                <li>
                  Technical architecture and design
                </li>
                
                <li>
                  Software development using Java fameworks (Spring, Springboot, Hibernate, Grails, etc)
                </li>
                <li>
                  Deployment and maintenance of applicatiosn in cloud platforms(AWS)
                </li>
                <li>
                  Experienced with core Javascript and other front-end frameworks (React, Angular, jquery etc). 
                </li>
                <li>
                  Application Security(underestanding of concepts and performing reviews/audits)
                </li>
              </ul>
            </div>

          </div>

          <div className="row-space"></div>

        </div>

      </div>
    </Element>)
  }
}
export default About;
